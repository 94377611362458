document.addEventListener("DOMContentLoaded", function () {
  const main = new Main();
});

class Main {
  constructor() {
    this.header = document.querySelector(".header");
    this.sides = document.querySelectorAll(".side");
    this._observers = [];
    this._init();
  }

  set observers(val) {
    this._observers.push(val);
  }

  get observers() {
    return this._observers;
  }

  _init() {
    new MobileMenu();
  }

  _paceDone() {
    this._scrollInit();
  }

  _inviewAnimation(el, inview) {
    if (inview) {
      el.classList.add("inview");
    } else {
      el.classList.remove("inview");
    }
  }

  _navAnimation(el, inview) {
    if (inview) {
      this.header.classList.remove("triggered");
    } else {
      this.header.classList.add("triggered");
    }
  }

  _sideAnimation(el, inview) {
    if (inview) {
      this.sides.forEach((side) => side.classList.add("inview"));
    } else {
      this.sides.forEach((side) => side.classList.remove("inview"));
    }
  }

  _textAnimation(el, inview) {
    if (inview) {
      const ta = new TweenTextAnimation(el);
      ta.animate();
    }
  }

  _toggleSlideAnimation(el, inview) {
    if (inview) {
      this.hero.start();
    } else {
      this.hero.stop();
    }
  }

  _destroyObservers() {
    this.observers.forEach((ob) => {
      ob.destroy();
    });
  }

  destroy() {
    this._destroyObservers();
  }

  _scrollInit() {
    this.observers = new ScrollObserver(
      ".nav-trigger",
      this._navAnimation.bind(this),
      { once: false }
    );
    this.observers = new ScrollObserver(".cover-slide", this._inviewAnimation);
    this.observers = new ScrollObserver(".appear", this._inviewAnimation);
    this.observers = new ScrollObserver(
      ".tween-animate-title",
      this._textAnimation,
      { rootMargin: "-200px 0px" }
    );
    this.observers = new ScrollObserver(
      ".swiper-container",
      this._toggleSlideAnimation.bind(this),
      { once: false }
    );
    this.observers = new ScrollObserver(
      "#main-content",
      this._sideAnimation.bind(this),
      { once: false, rootMargin: "-300px 0px" }
    );
  }
}

// ヘッダースクロール色変更
jQuery(window).on("scroll", function () {
  if (60 < jQuery(this).scrollTop()) {
    jQuery(".header").addClass("change-color");
  } else {
    jQuery(".header").removeClass("change-color");
  }
});

jQuery(window).on("scroll", function () {
  if (400 < jQuery(this).scrollTop()) {
    jQuery(".header").addClass("change-color");
  } else {
    jQuery(".header").removeClass("change-color");
  }
});

jQuery(window).on("scroll", function () {
  if (750 < jQuery(this).scrollTop()) {
    // 1000px以上スクロールしたら
    jQuery("#global-nav").addClass("m_fixed");
  } else {
    jQuery("#global-nav").removeClass("m_fixed");
  }
});

//faqページ
jQuery(function ($) {
  jQuery(".js-accordion-title").on("click", function () {
    /*クリックでコンテンツを開閉*/
    jQuery(this).next().slideToggle(200);
    /*矢印の向きを変更*/
    jQuery(this).toggleClass("open", 200);
  });
});

var $grid = jQuery(".accordion__panel-wrap"),
  emptyCells = [],
  i;

// 子パネル (li.cell) の数だけ空の子パネル (li.cell.is-empty) を追加する。
for (i = 0; i < $grid.find(".accordion__panel").length; i++) {
  emptyCells.push($("<div>", { class: "accordion__panel is-empty" }));
}

$grid.append(emptyCells);



// トップへ戻るボタン
jQuery(function() {
  var appear = false;
  var pagetop = $('.footer__scroll');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {  //100pxスクロールしたら
      if (appear == false) {
        appear = true;
        pagetop.stop().animate({
          'bottom': '50px' //下から50pxの位置に
        }, 300); //0.3秒かけて現れる
      }
    } else {
      if (appear) {
        appear = false;
        pagetop.stop().animate({
          'bottom': '-50px' //下から-50pxの位置に
        }, 300); //0.3秒かけて隠れる
      }
    }
  });
  pagetop.click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500); //0.5秒かけてトップへ戻る
    return false;
  });
});



// スクロール
var pagetopBtn = jQuery(".footer__scroll");
pagetopBtn.hide();

jQuery(window).scroll(function () {
  if (jQuery(this).scrollTop() > 400) {
    pagetopBtn.fadeIn();
  } else {
    pagetopBtn.fadeOut();
  }
});

jQuery(window).scroll(function () {
  var height = jQuery(document).height();
  var position = jQuery(window).height() + jQuery(window).scrollTop();
  var footer = jQuery(".footer__bottom").outerHeight();
  if (height - position < footer) {
    pagetopBtn.css({
      bottom: 130,
    });
  } else {
    pagetopBtn.css({
      position: "fixed",
      bottom: 10,
    });
  }
});

// .s_05 .accordion_one
jQuery(function () {
  //.accordion_oneの中の.accordion_headerがクリックされたら
  jQuery(".s_05 .accordion_one .accordion_header").click(function () {
    //クリックされた.accordion_oneの中の.accordion_headerに隣接する.accordion_innerが開いたり閉じたりする。
    jQuery(this).next(".accordion_inner").slideToggle();
    jQuery(this).toggleClass("open");
  });
  //閉じるボタンがクリックされたら
  jQuery(".s_05 a.close_btn").click(function () {
    //クリックされたa.close_btnの親要素の.accordion_oneの.accordion_innerを閉じる。
    jQuery(this).parents(".s_05 .accordion_one .accordion_inner").slideUp();
    jQuery(".s_05 .accordion_one .accordion_header").removeClass("open");
  });
});

// ページ内リンク
jQuery(function () {
  // 閉じるボタンをクリックした場合に処理
  jQuery(".s_05 a.close_btn").click(function () {
    // 移動先を0px上にずらす
    var adjust = 100;
    // スクロールの速度
    var speed = 500; // ミリ秒
    // アンカーの値取得
    var href = jQuery(this).attr("href");
    // 移動先を取得
    var target = jQuery(href == "#" || href == "" ? "html" : href);
    // 移動先を調整
    var position = target.offset().top - adjust;
    // スムーススクロール
    jQuery("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});




// 現在のページ表示
$(function(){
  $('.global-list li > a').each(function(){
    var target = $(this).attr('href');
    if(location.href.match(target)) {
      $(this).parent().addClass('current');
    } else {
      $(this).parent().removeClass('current');
    }
  });
});




// タブメニュー切り替え

//任意のタブにURLからリンクするための設定
function GethashID (hashIDName){
  if(hashIDName){
    //タブ設定
    $('.tab li').find('a').each(function() { //タブ内のaタグ全てを取得
      var idName = $(this).attr('href'); //タブ内のaタグのリンク名（例）#lunchの値を取得 
      if(idName == hashIDName){ //リンク元の指定されたURLのハッシュタグ（例）http://example.com/#lunch←この#の値とタブ内のリンク名（例）#lunchが同じかをチェック
        var parentElm = $(this).parent(); //タブ内のaタグの親要素（li）を取得
        $('.tab li').removeClass("active"); //タブ内のliについているactiveクラスを取り除き
        $(parentElm).addClass("active"); //リンク元の指定されたURLのハッシュタグとタブ内のリンク名が同じであれば、liにactiveクラスを追加
        //表示させるエリア設定
        $(".area").removeClass("is-active"); //もともとついているis-activeクラスを取り除き
        $(hashIDName).addClass("is-active"); //表示させたいエリアのタブリンク名をクリックしたら、表示エリアにis-activeクラスを追加 
      }
    });
  }
}

//タブをクリックしたら
$('.tab a').on('click', function() {
  var idName = $(this).attr('href'); //タブ内のリンク名を取得  
  GethashID (idName);//設定したタブの読み込みと
  return false;//aタグを無効にする
});


// 上記の動きをページが読み込まれたらすぐに動かす
$(window).on('load', function () {
    $('.tab li:first-of-type').addClass("active"); //最初のliにactiveクラスを追加
    $('.area:first-of-type').addClass("is-active"); //最初の.areaにis-activeクラスを追加
  var hashName = location.hash; //リンク元の指定されたURLのハッシュタグを取得
  GethashID (hashName);//設定したタブの読み込み
});



